var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"app-form"},[_c('v-expansion-panels',{model:{value:(_vm.activePanel),callback:function ($$v) {_vm.activePanel=$$v},expression:"activePanel"}},[_c('v-expansion-panel',{attrs:{"disabled":!_vm.panelsEnabled[0]}},[_c('v-expansion-panel-header',[_vm._v("Position information")]),_c('v-expansion-panel-content',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("What position are you applying for?")]),_c('v-select',{attrs:{"color":"primary","error-messages":errors,"items":_vm.lookupPosition,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_Position),callback:function ($$v) {_vm.$set(_vm.fdata, "app_Position", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_Position"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100, required: _vm.fdata.app_Position == 97 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Please specify","maxlength":"100","error-messages":errors,"disabled":!_vm.app_PositionText_enabled,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_PositionText),callback:function ($$v) {_vm.$set(_vm.fdata, "app_PositionText", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_PositionText"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex justify-center pt-4"},[_c('v-btn',{attrs:{"disabled":invalid,"color":"blue accent-1","outlined":"","rounded":""},on:{"click":function($event){return _vm.openPanel(1)}}},[_vm._v("Next section")])],1)]}}])})],1)],1),_c('v-expansion-panel',{attrs:{"disabled":!_vm.panelsEnabled[1]}},[_c('v-expansion-panel-header',[_vm._v("Personal details")]),_c('v-expansion-panel-content',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"color":"primary","label":"Title","error-messages":errors,"items":_vm.lookupTitle,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_Title),callback:function ($$v) {_vm.$set(_vm.fdata, "app_Title", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_Title"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100, required: _vm.fdata.app_Title == 97 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Specify your title","maxlength":"100","error-messages":errors,"disabled":!_vm.app_TitleText_enabled,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_TitleText),callback:function ($$v) {_vm.$set(_vm.fdata, "app_TitleText", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_TitleText"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true, max: 100, valid_name: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"First name","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_FirstName),callback:function ($$v) {_vm.$set(_vm.fdata, "app_FirstName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_FirstName"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100, valid_name: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Middle name(s)","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_MiddleName),callback:function ($$v) {_vm.$set(_vm.fdata, "app_MiddleName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_MiddleName"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true, max: 100, valid_name: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Surname","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_Surname),callback:function ($$v) {_vm.$set(_vm.fdata, "app_Surname", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_Surname"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{
                  required: true,
                  valid_date_format: true,
                  age_check: { opt: 'Minimum', ageLimit: 16 },
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Date of birth (example 28-09-1982)","maxlength":"20","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_DateOfBirth),callback:function ($$v) {_vm.$set(_vm.fdata, "app_DateOfBirth", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_DateOfBirth"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true, max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Mobile / Telephone number","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_MobileNumber),callback:function ($$v) {_vm.$set(_vm.fdata, "app_MobileNumber", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_MobileNumber"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true, max: 100, email: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Your E-mail","maxlength":"100","error-messages":errors,"hint":"Please note: a copy of your application will be sent to this e-mail when you submit","persistent-hint":"","clearable":"","outlined":""},model:{value:(_vm.fdata.app_Email),callback:function ($$v) {_vm.$set(_vm.fdata, "app_Email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_Email"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex justify-center pt-4"},[_c('v-btn',{attrs:{"disabled":invalid,"color":"blue accent-1","outlined":"","rounded":""},on:{"click":function($event){return _vm.openPanel(2)}}},[_vm._v("Next section")])],1)]}}])})],1)],1),_c('v-expansion-panel',{attrs:{"disabled":!_vm.panelsEnabled[2]}},[_c('v-expansion-panel-header',[_vm._v("Your address")]),_c('v-expansion-panel-content',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var invalid = ref.invalid;
return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100, required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"House number / First line of address","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_Address1),callback:function ($$v) {_vm.$set(_vm.fdata, "app_Address1", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_Address1"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Street / Road","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_Address2),callback:function ($$v) {_vm.$set(_vm.fdata, "app_Address2", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_Address2"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Locality","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_Address3),callback:function ($$v) {_vm.$set(_vm.fdata, "app_Address3", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_Address3"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true, max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Town / City","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_Town),callback:function ($$v) {_vm.$set(_vm.fdata, "app_Town", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_Town"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"County","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_County),callback:function ($$v) {_vm.$set(_vm.fdata, "app_County", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_County"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{
                  required: true,
                  max: 10,
                  valid_uk_postcode: true,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Postcode","maxlength":"10","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_Postcode),callback:function ($$v) {_vm.$set(_vm.fdata, "app_Postcode", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_Postcode"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-combobox',{attrs:{"color":"primary","label":"Country","error-messages":errors,"items":_vm.lookupCountry,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_Country),callback:function ($$v) {_vm.$set(_vm.fdata, "app_Country", $$v)},expression:"fdata.app_Country"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex justify-center pt-4"},[_c('v-btn',{attrs:{"disabled":invalid,"color":"blue accent-1","outlined":"","rounded":""},on:{"click":function($event){return _vm.openPanel(3)}}},[_vm._v("Next section")])],1)]}}])})],1)],1),_c('v-expansion-panel',{attrs:{"disabled":!_vm.panelsEnabled[3]}},[_c('v-expansion-panel-header',[_vm._v("National insurance")]),_c('v-expansion-panel-content',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var invalid = ref.invalid;
return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 20 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',[_vm._v("Your National Insurance Number (N. I. Number)")]),_c('v-text-field',{attrs:{"color":"primary","maxlength":"20","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_EmpNI),callback:function ($$v) {_vm.$set(_vm.fdata, "app_EmpNI", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_EmpNI"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',[_vm._v("Name of your Next of Kin / Emergency Contact")]),_c('v-text-field',{attrs:{"color":"primary","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_NextOfKin),callback:function ($$v) {_vm.$set(_vm.fdata, "app_NextOfKin", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_NextOfKin"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',[_vm._v("Next of Kin / Emergency Contact's relationship to you")]),_c('v-text-field',{attrs:{"color":"primary","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_NextOfKinRel),callback:function ($$v) {_vm.$set(_vm.fdata, "app_NextOfKinRel", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_NextOfKinRel"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',[_vm._v("Next of Kin / Emergency Contact's mobile / Telephone number")]),_c('v-text-field',{attrs:{"color":"primary","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_NextOfKinTel),callback:function ($$v) {_vm.$set(_vm.fdata, "app_NextOfKinTel", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_NextOfKinTel"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex justify-center pt-4"},[_c('v-btn',{attrs:{"disabled":invalid,"color":"blue accent-1","outlined":"","rounded":""},on:{"click":function($event){return _vm.openPanel(4)}}},[_vm._v("Next section")])],1)]}}])})],1)],1),_c('v-expansion-panel',{attrs:{"disabled":!_vm.panelsEnabled[4]}},[_c('v-expansion-panel-header',[_vm._v("Are you applying for:")]),_c('v-expansion-panel-content',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var invalid = ref.invalid;
return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',[_vm._v("Temporary work – such as summer or holiday work?")]),_c('v-select',{attrs:{"color":"primary","error-messages":errors,"items":_vm.lookupYesNo,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_EmpTemp),callback:function ($$v) {_vm.$set(_vm.fdata, "app_EmpTemp", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_EmpTemp"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',[_vm._v("Regular part-time work?")]),_c('v-select',{attrs:{"color":"primary","error-messages":errors,"items":_vm.lookupYesNo,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_EmpRegPartTime),callback:function ($$v) {_vm.$set(_vm.fdata, "app_EmpRegPartTime", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_EmpRegPartTime"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',[_vm._v("Regular full-time work?")]),_c('v-select',{attrs:{"color":"primary","error-messages":errors,"items":_vm.lookupYesNo,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_EmpRegFullTime),callback:function ($$v) {_vm.$set(_vm.fdata, "app_EmpRegFullTime", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_EmpRegFullTime"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex justify-center pt-4"},[_c('v-btn',{attrs:{"disabled":invalid,"color":"blue accent-1","outlined":"","rounded":""},on:{"click":function($event){return _vm.openPanel(5)}}},[_vm._v("Next section")])],1)]}}])})],1)],1),_c('v-expansion-panel',{attrs:{"disabled":!_vm.panelsEnabled[5]}},[_c('v-expansion-panel-header',[_vm._v("Days / hours available:")]),_c('v-expansion-panel-content',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var invalid = ref.invalid;
return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 4, between: [1, 24] }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Monday","maxlength":"4","prefix":"hours","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_AvMonday),callback:function ($$v) {_vm.$set(_vm.fdata, "app_AvMonday", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_AvMonday"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 4, between: [1, 24] }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Tuesday","maxlength":"4","prefix":"hours","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_AvTuesday),callback:function ($$v) {_vm.$set(_vm.fdata, "app_AvTuesday", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_AvTuesday"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 4, between: [1, 24] }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Wednesday","maxlength":"4","prefix":"hours","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_AvWednesday),callback:function ($$v) {_vm.$set(_vm.fdata, "app_AvWednesday", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_AvWednesday"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 4, between: [1, 24] }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Thursday","maxlength":"4","prefix":"hours","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_AvThursday),callback:function ($$v) {_vm.$set(_vm.fdata, "app_AvThursday", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_AvThursday"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 4, between: [1, 24] }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Friday","maxlength":"4","prefix":"hours","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_AvFriday),callback:function ($$v) {_vm.$set(_vm.fdata, "app_AvFriday", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_AvFriday"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 4, between: [1, 24] }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Saturday","maxlength":"4","prefix":"hours","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_AvSaturday),callback:function ($$v) {_vm.$set(_vm.fdata, "app_AvSaturday", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_AvSaturday"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 4, between: [1, 24] }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Sunday","maxlength":"4","prefix":"hours","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_AvSunday),callback:function ($$v) {_vm.$set(_vm.fdata, "app_AvSunday", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_AvSunday"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{
                  required: true,
                  valid_date_format: true,
                  current_date_compare: { opt: 'gteq' },
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',[_vm._v("If employed, on what date can you start working")]),_c('v-text-field',{staticClass:"mt-2",attrs:{"color":"primary","maxlength":"20","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_AvStartDate),callback:function ($$v) {_vm.$set(_vm.fdata, "app_AvStartDate", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_AvStartDate"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',[_vm._v("Can you work evenings?")]),_c('v-select',{staticClass:"mt-2",attrs:{"color":"primary","error-messages":errors,"items":_vm.lookupYesNo,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_AvCanWorkEv),callback:function ($$v) {_vm.$set(_vm.fdata, "app_AvCanWorkEv", $$v)},expression:"fdata.app_AvCanWorkEv"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',[_vm._v("Do you have a full or provisional UK Driving Licence?")]),_c('v-select',{staticClass:"mt-2",attrs:{"color":"primary","error-messages":errors,"items":_vm.lookupDrivingLicence,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_AvDLtype),callback:function ($$v) {_vm.$set(_vm.fdata, "app_AvDLtype", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_AvDLtype"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',[_vm._v("Do you have your own transport?")]),_c('v-select',{staticClass:"mt-2",attrs:{"color":"primary","error-messages":errors,"items":_vm.lookupYesNo,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_AvOwnTpt),callback:function ($$v) {_vm.$set(_vm.fdata, "app_AvOwnTpt", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_AvOwnTpt"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('label',[_vm._v("Do you have an On-Line DBS which we can check; if so please provide the Reference Number?")]),_c('v-select',{staticClass:"mt-2",attrs:{"color":"primary","items":_vm.lookupYesNo,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_AvDBScheck),callback:function ($$v) {_vm.$set(_vm.fdata, "app_AvDBScheck", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_AvDBScheck"}})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"DBS Update Service Ref No","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_AvDBSref),callback:function ($$v) {_vm.$set(_vm.fdata, "app_AvDBSref", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_AvDBSref"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex justify-center pt-4"},[_c('v-btn',{attrs:{"disabled":invalid,"color":"blue accent-1","outlined":"","rounded":""},on:{"click":function($event){return _vm.openPanel(6)}}},[_vm._v("Next section")])],1)]}}])})],1)],1),_c('v-expansion-panel',{attrs:{"disabled":!_vm.panelsEnabled[6]}},[_c('v-expansion-panel-header',[_vm._v("Employment history: Present or most recent employer's details")]),_c('v-expansion-panel-content',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var invalid = ref.invalid;
return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true, max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Employer's name","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_PempName),callback:function ($$v) {_vm.$set(_vm.fdata, "app_PempName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_PempName"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true, max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Telephone number","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_PempTel),callback:function ($$v) {_vm.$set(_vm.fdata, "app_PempTel", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_PempTel"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true, max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Type of business","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_PempBType),callback:function ($$v) {_vm.$set(_vm.fdata, "app_PempBType", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_PempBType"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true, max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Post held","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_PempPos),callback:function ($$v) {_vm.$set(_vm.fdata, "app_PempPos", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_PempPos"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{
                  required: true,
                  valid_date_format: true,
                  current_date_compare: { opt: 'lteq' },
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"From (date)","maxlength":"20","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_PempFromDate),callback:function ($$v) {_vm.$set(_vm.fdata, "app_PempFromDate", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_PempFromDate"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{
                  valid_date_format: true,
                  data_item_compare: {
                    opt: 'gteq',
                    targetValue: _vm.fdata.app_PempFromDate,
                    dataType: 'date',
                  },
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"To (date)","maxlength":"20","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_PempToDate),callback:function ($$v) {_vm.$set(_vm.fdata, "app_PempToDate", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_PempToDate"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true, max: 1000 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-textarea',{attrs:{"color":"primary","label":"Responsibilities","maxlength":"1000","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_PempResps),callback:function ($$v) {_vm.$set(_vm.fdata, "app_PempResps", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_PempResps"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Notice required","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_PempNotice),callback:function ($$v) {_vm.$set(_vm.fdata, "app_PempNotice", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_PempNotice"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex justify-center pt-4"},[_c('v-btn',{attrs:{"disabled":invalid,"color":"blue accent-1","outlined":"","rounded":""},on:{"click":function($event){return _vm.openPanel(7)}}},[_vm._v("Next section")])],1)]}}])})],1)],1),_c('v-expansion-panel',{attrs:{"disabled":!_vm.panelsEnabled[7]}},[_c('v-expansion-panel-header',[_vm._v("Employment history: Address of present or most recent employer")]),_c('v-expansion-panel-content',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var invalid = ref.invalid;
return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100, required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"House number / First line of address","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_PempAddress1),callback:function ($$v) {_vm.$set(_vm.fdata, "app_PempAddress1", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_PempAddress1"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Street / Road","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_PempAddress2),callback:function ($$v) {_vm.$set(_vm.fdata, "app_PempAddress2", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_PempAddress2"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Locality","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_PempAddress3),callback:function ($$v) {_vm.$set(_vm.fdata, "app_PempAddress3", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_PempAddress3"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true, max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Town / City","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_PempTown),callback:function ($$v) {_vm.$set(_vm.fdata, "app_PempTown", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_PempTown"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"County","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_PempCounty),callback:function ($$v) {_vm.$set(_vm.fdata, "app_PempCounty", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_PempCounty"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{
                  required: true,
                  max: 10,
                  valid_uk_postcode: true,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Postcode","maxlength":"10","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_PempPostcode),callback:function ($$v) {_vm.$set(_vm.fdata, "app_PempPostcode", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_PempPostcode"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-combobox',{attrs:{"color":"primary","label":"Country","error-messages":errors,"items":_vm.lookupCountry,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_PempCountry),callback:function ($$v) {_vm.$set(_vm.fdata, "app_PempCountry", $$v)},expression:"fdata.app_PempCountry"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex justify-center pt-4"},[_c('v-btn',{attrs:{"disabled":invalid,"color":"blue accent-1","outlined":"","rounded":""},on:{"click":function($event){return _vm.openPanel(8)}}},[_vm._v("Next section")])],1)]}}])})],1)],1),_c('v-expansion-panel',{attrs:{"disabled":!_vm.panelsEnabled[8]}},[_c('v-expansion-panel-header',[_vm._v(" Previous employment")]),_c('v-expansion-panel-content',[_c('div',[_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","small":""},on:{"click":_vm.addNewSection_AppPrevEmp}},[_vm._v("Add new section")]),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","small":""},on:{"click":_vm.removeAllSections_AppPrevEmp}},[_vm._v(" Remove all sections ")])],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var invalid = ref.invalid;
return [_vm._l((_vm.fdata.subForm_AppPrevEmp),function(item,i){return _c('div',{key:i,staticClass:"app-subform-section"},[_c('v-chip',{staticClass:"mb-3",attrs:{"close":"","color":"secondary","text-color":"white"},on:{"click:close":function($event){return _vm.removeSection_AppPrevEmp(i)}}},[_vm._v(" Section ("+_vm._s(i + 1)+" of "+_vm._s(_vm.fdata.subForm_AppPrevEmp.length)+") ")]),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Name of employer","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(item.app_PrevName),callback:function ($$v) {_vm.$set(item, "app_PrevName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.app_PrevName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Position held","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(item.app_PrevPos),callback:function ($$v) {_vm.$set(item, "app_PrevPos", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.app_PrevPos"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ numeric: true, max: 7 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Salary","maxlength":"7","error-messages":errors,"clearable":"","outlined":""},model:{value:(item.app_PrevSal),callback:function ($$v) {_vm.$set(item, "app_PrevSal", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.app_PrevSal"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('label',[_vm._v("Salary frequency")]),_c('v-select',{attrs:{"color":"primary","items":_vm.lookupSalaryFrequency,"clearable":"","outlined":""},model:{value:(item.app_PrevSalUnit),callback:function ($$v) {_vm.$set(item, "app_PrevSalUnit", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.app_PrevSalUnit"}})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{
                    valid_date_format: true,
                    current_date_compare: { opt: 'lteq' },
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"From (date)","maxlength":"20","error-messages":errors,"clearable":"","outlined":""},model:{value:(item.app_PrevFromDate),callback:function ($$v) {_vm.$set(item, "app_PrevFromDate", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.app_PrevFromDate"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{
                    valid_date_format: true,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"To (date)","maxlength":"20","error-messages":errors,"clearable":"","outlined":""},model:{value:(item.app_PrevToDate),callback:function ($$v) {_vm.$set(item, "app_PrevToDate", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.app_PrevToDate"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 1000 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-textarea',{attrs:{"color":"primary","label":"Reasons for leaving","maxlength":"1000","error-messages":errors,"clearable":"","outlined":""},model:{value:(item.app_PrevReasons),callback:function ($$v) {_vm.$set(item, "app_PrevReasons", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.app_PrevReasons"}})]}}],null,true)})],1)],1)],1)}),_c('div',{staticClass:"d-flex justify-center pt-4"},[_c('v-btn',{attrs:{"disabled":invalid,"color":"blue accent-1","outlined":"","rounded":""},on:{"click":function($event){return _vm.openPanel(9)}}},[_vm._v("Next section")])],1)]}}])})],1)],1),_c('v-expansion-panel',{attrs:{"disabled":!_vm.panelsEnabled[9]}},[_c('v-expansion-panel-header',[_vm._v("Education")]),_c('v-expansion-panel-content',[_c('div',[_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","small":""},on:{"click":_vm.addNewSection_AppEducation}},[_vm._v("Add new section")]),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","small":""},on:{"click":_vm.removeAllSections_AppEducation}},[_vm._v(" Remove all sections ")])],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var invalid = ref.invalid;
return [_vm._l((_vm.fdata.subForm_AppEducation),function(item,i){return _c('div',{key:i,staticClass:"app-subform-section"},[_c('v-chip',{staticClass:"mb-3",attrs:{"close":"","color":"secondary","text-color":"white"},on:{"click:close":function($event){return _vm.removeSection_AppEducation(i)}}},[_vm._v(" Section ("+_vm._s(i + 1)+" of "+_vm._s(_vm.fdata.subForm_AppEducation.length)+") ")]),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"School / College / University","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(item.app_EduOrg),callback:function ($$v) {_vm.$set(item, "app_EduOrg", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.app_EduOrg"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{
                    valid_date_format: true,
                    current_date_compare: { opt: 'lteq' },
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"From (date)","maxlength":"20","error-messages":errors,"clearable":"","outlined":""},model:{value:(item.app_EduFromDate),callback:function ($$v) {_vm.$set(item, "app_EduFromDate", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.app_EduFromDate"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{
                    valid_date_format: true,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"To (date)","maxlength":"20","error-messages":errors,"clearable":"","outlined":""},model:{value:(item.app_EduToDate),callback:function ($$v) {_vm.$set(item, "app_EduToDate", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.app_EduToDate"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 1000 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-textarea',{attrs:{"color":"primary","label":"Results / qualification","maxlength":"1000","error-messages":errors,"clearable":"","outlined":""},model:{value:(item.app_EduResults),callback:function ($$v) {_vm.$set(item, "app_EduResults", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.app_EduResults"}})]}}],null,true)})],1)],1)],1)}),_c('div',{staticClass:"d-flex justify-center pt-4"},[_c('v-btn',{attrs:{"disabled":invalid,"color":"blue accent-1","outlined":"","rounded":""},on:{"click":function($event){return _vm.openPanel(10)}}},[_vm._v("Next section")])],1)]}}])})],1)],1),_c('v-expansion-panel',{attrs:{"disabled":!_vm.panelsEnabled[10]}},[_c('v-expansion-panel-header',[_vm._v("Trainings")]),_c('v-expansion-panel-content',[_c('div',[_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","small":""},on:{"click":_vm.addNewSection_AppTraining}},[_vm._v("Add new section")]),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","small":""},on:{"click":_vm.removeAllSections_AppTraining}},[_vm._v(" Remove all sections ")])],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var invalid = ref.invalid;
return [_vm._l((_vm.fdata.subForm_AppTraining),function(item,i){return _c('div',{key:i,staticClass:"app-subform-section"},[_c('v-chip',{staticClass:"mb-3",attrs:{"close":"","color":"secondary","text-color":"white"},on:{"click:close":function($event){return _vm.removeSection_AppTraining(i)}}},[_vm._v(" Section ("+_vm._s(i + 1)+" of "+_vm._s(_vm.fdata.subForm_AppTraining.length)+") ")]),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 1000 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('label',[_vm._v("Training details (Qualifications / Certificates obtained)")]),_c('v-textarea',{staticClass:"mt-2",attrs:{"color":"primary","maxlength":"1000","error-messages":errors,"clearable":"","outlined":""},model:{value:(item.app_TrnOrg),callback:function ($$v) {_vm.$set(item, "app_TrnOrg", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.app_TrnOrg"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{
                    valid_date_format: true,
                    current_date_compare: { opt: 'lteq' },
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Date obtained","maxlength":"20","error-messages":errors,"clearable":"","outlined":""},model:{value:(item.app_TrnDateObtained),callback:function ($$v) {_vm.$set(item, "app_TrnDateObtained", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.app_TrnDateObtained"}})]}}],null,true)})],1)],1)],1)}),_c('div',{staticClass:"d-flex justify-center pt-4"},[_c('v-btn',{attrs:{"disabled":invalid,"color":"blue accent-1","outlined":"","rounded":""},on:{"click":function($event){return _vm.openPanel(11)}}},[_vm._v("Next section")])],1)]}}])})],1)],1),_c('v-expansion-panel',{attrs:{"disabled":!_vm.panelsEnabled[11]}},[_c('v-expansion-panel-header',[_vm._v("Additional information")]),_c('v-expansion-panel-content',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var invalid = ref.invalid;
return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 1000 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('label',[_vm._v("Is there anything else that you would like to say in support of your application")]),_c('v-textarea',{staticClass:"mt-2",attrs:{"color":"primary","maxlength":"1000","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_AddInfo),callback:function ($$v) {_vm.$set(_vm.fdata, "app_AddInfo", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_AddInfo"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex justify-center pt-4"},[_c('v-btn',{attrs:{"disabled":invalid,"color":"blue accent-1","outlined":"","rounded":""},on:{"click":function($event){return _vm.openPanel(12)}}},[_vm._v("Next section")])],1)]}}])})],1)],1),_c('v-expansion-panel',{attrs:{"disabled":!_vm.panelsEnabled[12]}},[_c('v-expansion-panel-header',[_vm._v("First reference")]),_c('v-expansion-panel-content',[_c('p',[_vm._v(" Please give details of two referees, the first of which should be your most recent employer. The second should be a personal reference from a person (not a relative or friend) who has known you for at least three years and is a professional (e.g. teacher, nurse, Minister of Religion). ")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var invalid = ref.invalid;
return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100, required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Name","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_RefName),callback:function ($$v) {_vm.$set(_vm.fdata, "app_RefName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_RefName"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Occupation","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_RefOcu),callback:function ($$v) {_vm.$set(_vm.fdata, "app_RefOcu", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_RefOcu"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Company name","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_RefCompName),callback:function ($$v) {_vm.$set(_vm.fdata, "app_RefCompName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_RefCompName"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true, max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Mobile / telephone number","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_RefTel),callback:function ($$v) {_vm.$set(_vm.fdata, "app_RefTel", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_RefTel"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true, max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Relationship to applicant","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_RefRel),callback:function ($$v) {_vm.$set(_vm.fdata, "app_RefRel", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_RefRel"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"color":"primary","label":"May we take up this reference now","error-messages":errors,"items":_vm.lookupYesNo,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_RefTakeNow),callback:function ($$v) {_vm.$set(_vm.fdata, "app_RefTakeNow", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_RefTakeNow"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true, max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"House number / First line of address","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_RefAddress1),callback:function ($$v) {_vm.$set(_vm.fdata, "app_RefAddress1", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_RefAddress1"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Street / Road","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_RefAddress2),callback:function ($$v) {_vm.$set(_vm.fdata, "app_RefAddress2", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_RefAddress2"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Locality","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_RefAddress3),callback:function ($$v) {_vm.$set(_vm.fdata, "app_RefAddress3", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_RefAddress3"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Town / City","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_RefTown),callback:function ($$v) {_vm.$set(_vm.fdata, "app_RefTown", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_RefTown"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"County","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_RefCounty),callback:function ($$v) {_vm.$set(_vm.fdata, "app_RefCounty", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_RefCounty"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{
                  required: true,
                  max: 10,
                  valid_uk_postcode: true,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Postcode","maxlength":"10","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_RefPostcode),callback:function ($$v) {_vm.$set(_vm.fdata, "app_RefPostcode", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_RefPostcode"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-combobox',{attrs:{"color":"primary","label":"Country","error-messages":errors,"items":_vm.lookupCountry,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_RefCountry),callback:function ($$v) {_vm.$set(_vm.fdata, "app_RefCountry", $$v)},expression:"fdata.app_RefCountry"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex justify-center pt-4"},[_c('v-btn',{attrs:{"disabled":invalid,"color":"blue accent-1","outlined":"","rounded":""},on:{"click":function($event){return _vm.openPanel(13)}}},[_vm._v("Next section")])],1)]}}])})],1)],1),_c('v-expansion-panel',{attrs:{"disabled":!_vm.panelsEnabled[13]}},[_c('v-expansion-panel-header',[_vm._v("Second reference")]),_c('v-expansion-panel-content',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var invalid = ref.invalid;
return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100, required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Name","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_RefName2),callback:function ($$v) {_vm.$set(_vm.fdata, "app_RefName2", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_RefName2"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Occupation","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_RefOcu2),callback:function ($$v) {_vm.$set(_vm.fdata, "app_RefOcu2", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_RefOcu2"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Company name","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_RefCompName2),callback:function ($$v) {_vm.$set(_vm.fdata, "app_RefCompName2", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_RefCompName2"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true, max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Mobile / telephone number","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_RefTel2),callback:function ($$v) {_vm.$set(_vm.fdata, "app_RefTel2", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_RefTel2"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true, max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Relationship to applicant","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_RefRel2),callback:function ($$v) {_vm.$set(_vm.fdata, "app_RefRel2", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_RefRel2"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{attrs:{"color":"primary","label":"May we take up this reference now","error-messages":errors,"items":_vm.lookupYesNo,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_RefTakeNow2),callback:function ($$v) {_vm.$set(_vm.fdata, "app_RefTakeNow2", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_RefTakeNow2"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true, max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"House number / First line of address","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_RefAddress11),callback:function ($$v) {_vm.$set(_vm.fdata, "app_RefAddress11", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_RefAddress11"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Street / Road","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_RefAddress22),callback:function ($$v) {_vm.$set(_vm.fdata, "app_RefAddress22", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_RefAddress22"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Locality","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_RefAddress23),callback:function ($$v) {_vm.$set(_vm.fdata, "app_RefAddress23", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_RefAddress23"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Town / City","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_RefTown2),callback:function ($$v) {_vm.$set(_vm.fdata, "app_RefTown2", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_RefTown2"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"County","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_RefCounty2),callback:function ($$v) {_vm.$set(_vm.fdata, "app_RefCounty2", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_RefCounty2"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{
                  required: true,
                  max: 10,
                  valid_uk_postcode: true,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"primary","label":"Postcode","maxlength":"10","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_RefPostcode2),callback:function ($$v) {_vm.$set(_vm.fdata, "app_RefPostcode2", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_RefPostcode2"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-combobox',{attrs:{"color":"primary","label":"Country","error-messages":errors,"items":_vm.lookupCountry,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_RefCountry2),callback:function ($$v) {_vm.$set(_vm.fdata, "app_RefCountry2", $$v)},expression:"fdata.app_RefCountry2"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex justify-center pt-4"},[_c('v-btn',{attrs:{"disabled":invalid,"color":"blue accent-1","outlined":"","rounded":""},on:{"click":function($event){return _vm.openPanel(14)}}},[_vm._v("Next section")])],1)]}}])})],1)],1),_c('v-expansion-panel',{attrs:{"disabled":!_vm.panelsEnabled[14]}},[_c('v-expansion-panel-header',[_vm._v("Declaration")]),_c('v-expansion-panel-content',[_c('ValidationObserver',{ref:"formRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var invalid = ref.invalid;
return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{
                  required: true,
                  data_item_compare: {
                    opt: 'eq',
                    targetValue: 2,
                    dataType: 'number',
                    msg: 'This question must be YES',
                  },
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',[_vm._v("I confirm that the information contained in this application is true and complete. I accept that providing deliberately false information could result in my dismissal.")]),_c('v-select',{staticClass:"mt-2",attrs:{"color":"primary","error-messages":errors,"items":_vm.lookupYesNo,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_DecInfoTrue),callback:function ($$v) {_vm.$set(_vm.fdata, "app_DecInfoTrue", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_DecInfoTrue"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{
                  required: true,
                  data_item_compare: {
                    opt: 'eq',
                    targetValue: 2,
                    dataType: 'number',
                    msg: 'This question must be YES',
                  },
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',[_vm._v("I confirm that I consider myself to be well enough in my physical and mental health to cope with the demands of the post I have applied for with Rehoboth24 Healthcare Solutions Ltd.")]),_c('v-select',{staticClass:"mt-2",attrs:{"color":"primary","error-messages":errors,"items":_vm.lookupYesNo,"clearable":"","outlined":""},model:{value:(_vm.fdata.app_DecWell),callback:function ($$v) {_vm.$set(_vm.fdata, "app_DecWell", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.app_DecWell"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"disabled":invalid,"loading":_vm.showBusyAni,"color":"primary","large":""},on:{"click":_vm.saveFormData}},[_vm._v("Submit Application")])],1),_c('div',{staticClass:"app-recaptcha-terms"},[_vm._v(" This site is protected by reCAPTCHA and the Google "),_c('a',{attrs:{"href":"https://policies.google.com/privacy"}},[_vm._v("Privacy Policy")]),_vm._v(" and "),_c('a',{attrs:{"href":"https://policies.google.com/terms"}},[_vm._v("Terms of Service")]),_vm._v(" apply. ")])]}}])})],1)],1)],1),_c('AsyncDialog',{ref:"confirmDialog"}),_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.showSnackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }