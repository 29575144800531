<template>
  <form class="app-form">
    <v-expansion-panels v-model="activePanel">
      <v-expansion-panel :disabled="!panelsEnabled[0]">
        <v-expansion-panel-header
          >Position information</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <ValidationObserver v-slot="{ invalid }">
            <v-row dense>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <label>What position are you applying for?</label>
                  <v-select
                    color="primary"
                    v-model.trim="fdata.app_Position"
                    :error-messages="errors"
                    :items="lookupPosition"
                    clearable
                    outlined
                  >
                  </v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100, required: fdata.app_Position == 97 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Please specify"
                    v-model.trim="fdata.app_PositionText"
                    maxlength="100"
                    :error-messages="errors"
                    :disabled="!app_PositionText_enabled"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <div class="d-flex justify-center pt-4">
              <v-btn
                @click="openPanel(1)"
                :disabled="invalid"
                color="blue accent-1"
                outlined
                rounded
                >Next section</v-btn
              >
            </div>
          </ValidationObserver>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel :disabled="!panelsEnabled[1]">
        <v-expansion-panel-header>Personal details</v-expansion-panel-header>
        <v-expansion-panel-content>
          <ValidationObserver v-slot="{ invalid }">
            <v-row dense>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <v-select
                    color="primary"
                    label="Title"
                    v-model.trim="fdata.app_Title"
                    :error-messages="errors"
                    :items="lookupTitle"
                    clearable
                    outlined
                  >
                  </v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100, required: fdata.app_Title == 97 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Specify your title"
                    v-model.trim="fdata.app_TitleText"
                    maxlength="100"
                    :error-messages="errors"
                    :disabled="!app_TitleText_enabled"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true, max: 100, valid_name: true }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="First name"
                    v-model.trim="fdata.app_FirstName"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100, valid_name: true }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Middle name(s)"
                    v-model.trim="fdata.app_MiddleName"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true, max: 100, valid_name: true }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Surname"
                    v-model.trim="fdata.app_Surname"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{
                    required: true,
                    valid_date_format: true,
                    age_check: { opt: 'Minimum', ageLimit: 16 },
                  }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Date of birth (example 28-09-1982)"
                    v-model.trim="fdata.app_DateOfBirth"
                    maxlength="20"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true, max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Mobile / Telephone number"
                    v-model.trim="fdata.app_MobileNumber"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true, max: 100, email: true }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Your E-mail"
                    v-model.trim="fdata.app_Email"
                    maxlength="100"
                    :error-messages="errors"
                    hint="Please note: a copy of your application will be sent to this e-mail when you submit"
                    persistent-hint
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <div class="d-flex justify-center pt-4">
              <v-btn
                @click="openPanel(2)"
                :disabled="invalid"
                color="blue accent-1"
                outlined
                rounded
                >Next section</v-btn
              >
            </div>
          </ValidationObserver>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel :disabled="!panelsEnabled[2]">
        <v-expansion-panel-header>Your address</v-expansion-panel-header>
        <v-expansion-panel-content>
          <ValidationObserver v-slot="{ invalid }">
            <v-row dense>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100, required: true }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="House number / First line of address"
                    v-model.trim="fdata.app_Address1"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Street / Road"
                    v-model.trim="fdata.app_Address2"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Locality"
                    v-model.trim="fdata.app_Address3"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true, max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Town / City"
                    v-model.trim="fdata.app_Town"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="County"
                    v-model.trim="fdata.app_County"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{
                    required: true,
                    max: 10,
                    valid_uk_postcode: true,
                  }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Postcode"
                    v-model.trim="fdata.app_Postcode"
                    maxlength="10"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <v-combobox
                    color="primary"
                    label="Country"
                    v-model="fdata.app_Country"
                    :error-messages="errors"
                    :items="lookupCountry"
                    clearable
                    outlined
                  >
                  </v-combobox>
                </ValidationProvider>
              </v-col>
            </v-row>
            <div class="d-flex justify-center pt-4">
              <v-btn
                @click="openPanel(3)"
                :disabled="invalid"
                color="blue accent-1"
                outlined
                rounded
                >Next section</v-btn
              >
            </div>
          </ValidationObserver>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel :disabled="!panelsEnabled[3]">
        <v-expansion-panel-header>National insurance</v-expansion-panel-header>
        <v-expansion-panel-content>
          <ValidationObserver v-slot="{ invalid }">
            <v-row dense>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 20 }"
                  v-slot="{ errors }"
                >
                  <label>Your National Insurance Number (N. I. Number)</label>
                  <v-text-field
                    color="primary"
                    v-model.trim="fdata.app_EmpNI"
                    maxlength="20"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100 }"
                  v-slot="{ errors }"
                >
                  <label>Name of your Next of Kin / Emergency Contact</label>
                  <v-text-field
                    color="primary"
                    v-model.trim="fdata.app_NextOfKin"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100 }"
                  v-slot="{ errors }"
                >
                  <label
                    >Next of Kin / Emergency Contact's relationship to
                    you</label
                  >
                  <v-text-field
                    color="primary"
                    v-model.trim="fdata.app_NextOfKinRel"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100 }"
                  v-slot="{ errors }"
                >
                  <label
                    >Next of Kin / Emergency Contact's mobile / Telephone
                    number</label
                  >
                  <v-text-field
                    color="primary"
                    v-model.trim="fdata.app_NextOfKinTel"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <div class="d-flex justify-center pt-4">
              <v-btn
                @click="openPanel(4)"
                :disabled="invalid"
                color="blue accent-1"
                outlined
                rounded
                >Next section</v-btn
              >
            </div>
          </ValidationObserver>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel :disabled="!panelsEnabled[4]">
        <v-expansion-panel-header
          >Are you applying for:</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <ValidationObserver v-slot="{ invalid }">
            <v-row dense>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <label
                    >Temporary work – such as summer or holiday work?</label
                  >
                  <v-select
                    color="primary"
                    v-model.trim="fdata.app_EmpTemp"
                    :error-messages="errors"
                    :items="lookupYesNo"
                    clearable
                    outlined
                  >
                  </v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <label>Regular part-time work?</label>
                  <v-select
                    color="primary"
                    v-model.trim="fdata.app_EmpRegPartTime"
                    :error-messages="errors"
                    :items="lookupYesNo"
                    clearable
                    outlined
                  >
                  </v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <label>Regular full-time work?</label>
                  <v-select
                    color="primary"
                    v-model.trim="fdata.app_EmpRegFullTime"
                    :error-messages="errors"
                    :items="lookupYesNo"
                    clearable
                    outlined
                  >
                  </v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
            <div class="d-flex justify-center pt-4">
              <v-btn
                @click="openPanel(5)"
                :disabled="invalid"
                color="blue accent-1"
                outlined
                rounded
                >Next section</v-btn
              >
            </div>
          </ValidationObserver>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel :disabled="!panelsEnabled[5]">
        <v-expansion-panel-header
          >Days / hours available:</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <ValidationObserver v-slot="{ invalid }">
            <v-row dense>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 4, between: [1, 24] }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Monday"
                    v-model.trim="fdata.app_AvMonday"
                    maxlength="4"
                    prefix="hours"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 4, between: [1, 24] }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Tuesday"
                    v-model.trim="fdata.app_AvTuesday"
                    maxlength="4"
                    prefix="hours"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 4, between: [1, 24] }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Wednesday"
                    v-model.trim="fdata.app_AvWednesday"
                    maxlength="4"
                    prefix="hours"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 4, between: [1, 24] }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Thursday"
                    v-model.trim="fdata.app_AvThursday"
                    maxlength="4"
                    prefix="hours"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 4, between: [1, 24] }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Friday"
                    v-model.trim="fdata.app_AvFriday"
                    maxlength="4"
                    prefix="hours"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 4, between: [1, 24] }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Saturday"
                    v-model.trim="fdata.app_AvSaturday"
                    maxlength="4"
                    prefix="hours"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 4, between: [1, 24] }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Sunday"
                    v-model.trim="fdata.app_AvSunday"
                    maxlength="4"
                    prefix="hours"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{
                    required: true,
                    valid_date_format: true,
                    current_date_compare: { opt: 'gteq' },
                  }"
                  v-slot="{ errors }"
                >
                  <label>If employed, on what date can you start working</label>
                  <v-text-field
                    class="mt-2"
                    color="primary"
                    v-model.trim="fdata.app_AvStartDate"
                    maxlength="20"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <label>Can you work evenings?</label>
                  <v-select
                    class="mt-2"
                    color="primary"
                    v-model="fdata.app_AvCanWorkEv"
                    :error-messages="errors"
                    :items="lookupYesNo"
                    clearable
                    outlined
                  >
                  </v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <label
                    >Do you have a full or provisional UK Driving
                    Licence?</label
                  >
                  <v-select
                    class="mt-2"
                    color="primary"
                    v-model.trim="fdata.app_AvDLtype"
                    :error-messages="errors"
                    :items="lookupDrivingLicence"
                    clearable
                    outlined
                  >
                  </v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <label>Do you have your own transport?</label>
                  <v-select
                    class="mt-2"
                    color="primary"
                    v-model.trim="fdata.app_AvOwnTpt"
                    :error-messages="errors"
                    :items="lookupYesNo"
                    clearable
                    outlined
                  >
                  </v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <label
                  >Do you have an On-Line DBS which we can check; if so please
                  provide the Reference Number?</label
                >
                <v-select
                  class="mt-2"
                  color="primary"
                  v-model.trim="fdata.app_AvDBScheck"
                  :items="lookupYesNo"
                  clearable
                  outlined
                >
                </v-select>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="DBS Update Service Ref No"
                    v-model.trim="fdata.app_AvDBSref"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <div class="d-flex justify-center pt-4">
              <v-btn
                @click="openPanel(6)"
                :disabled="invalid"
                color="blue accent-1"
                outlined
                rounded
                >Next section</v-btn
              >
            </div>
          </ValidationObserver>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel :disabled="!panelsEnabled[6]">
        <v-expansion-panel-header
          >Employment history: Present or most recent employer's
          details</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <ValidationObserver v-slot="{ invalid }">
            <v-row dense>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true, max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Employer's name"
                    v-model.trim="fdata.app_PempName"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true, max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Telephone number"
                    v-model.trim="fdata.app_PempTel"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true, max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Type of business"
                    v-model.trim="fdata.app_PempBType"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true, max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Post held"
                    v-model.trim="fdata.app_PempPos"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{
                    required: true,
                    valid_date_format: true,
                    current_date_compare: { opt: 'lteq' },
                  }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="From (date)"
                    v-model.trim="fdata.app_PempFromDate"
                    maxlength="20"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{
                    valid_date_format: true,
                    data_item_compare: {
                      opt: 'gteq',
                      targetValue: fdata.app_PempFromDate,
                      dataType: 'date',
                    },
                  }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="To (date)"
                    v-model.trim="fdata.app_PempToDate"
                    maxlength="20"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true, max: 1000 }"
                  v-slot="{ errors }"
                >
                  <v-textarea
                    color="primary"
                    label="Responsibilities"
                    v-model.trim="fdata.app_PempResps"
                    maxlength="1000"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-textarea>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Notice required"
                    v-model.trim="fdata.app_PempNotice"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <div class="d-flex justify-center pt-4">
              <v-btn
                @click="openPanel(7)"
                :disabled="invalid"
                color="blue accent-1"
                outlined
                rounded
                >Next section</v-btn
              >
            </div>
          </ValidationObserver>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel :disabled="!panelsEnabled[7]">
        <v-expansion-panel-header
          >Employment history: Address of present or most recent
          employer</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <ValidationObserver v-slot="{ invalid }">
            <v-row dense>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100, required: true }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="House number / First line of address"
                    v-model.trim="fdata.app_PempAddress1"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Street / Road"
                    v-model.trim="fdata.app_PempAddress2"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Locality"
                    v-model.trim="fdata.app_PempAddress3"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true, max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Town / City"
                    v-model.trim="fdata.app_PempTown"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="County"
                    v-model.trim="fdata.app_PempCounty"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{
                    required: true,
                    max: 10,
                    valid_uk_postcode: true,
                  }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Postcode"
                    v-model.trim="fdata.app_PempPostcode"
                    maxlength="10"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <v-combobox
                    color="primary"
                    label="Country"
                    v-model="fdata.app_PempCountry"
                    :error-messages="errors"
                    :items="lookupCountry"
                    clearable
                    outlined
                  >
                  </v-combobox>
                </ValidationProvider>
              </v-col>
            </v-row>
            <div class="d-flex justify-center pt-4">
              <v-btn
                @click="openPanel(8)"
                :disabled="invalid"
                color="blue accent-1"
                outlined
                rounded
                >Next section</v-btn
              >
            </div>
          </ValidationObserver>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel :disabled="!panelsEnabled[8]">
        <v-expansion-panel-header>
          Previous employment</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <div>
            <v-btn
              @click="addNewSection_AppPrevEmp"
              class="ma-1"
              color="primary"
              small
              >Add new section</v-btn
            >
            <v-btn
              @click="removeAllSections_AppPrevEmp"
              class="ma-1"
              color="primary"
              small
            >
              Remove all sections
            </v-btn>
          </div>
          <ValidationObserver v-slot="{ invalid }">
            <div
              class="app-subform-section"
              v-for="(item, i) in fdata.subForm_AppPrevEmp"
              :key="i"
            >
              <v-chip
                @click:close="removeSection_AppPrevEmp(i)"
                class="mb-3"
                close
                color="secondary"
                text-color="white"
              >
                Section ({{ i + 1 }} of {{ fdata.subForm_AppPrevEmp.length }})
              </v-chip>
              <v-row dense>
                <v-col cols="12">
                  <ValidationProvider
                    :immediate="true"
                    :rules="{ max: 100 }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      color="primary"
                      label="Name of employer"
                      v-model.trim="item.app_PrevName"
                      maxlength="100"
                      :error-messages="errors"
                      clearable
                      outlined
                    >
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider
                    :immediate="true"
                    :rules="{ max: 100 }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      color="primary"
                      label="Position held"
                      v-model.trim="item.app_PrevPos"
                      maxlength="100"
                      :error-messages="errors"
                      clearable
                      outlined
                    >
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider
                    :immediate="true"
                    :rules="{ numeric: true, max: 7 }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      color="primary"
                      label="Salary"
                      v-model.trim="item.app_PrevSal"
                      maxlength="7"
                      :error-messages="errors"
                      clearable
                      outlined
                    >
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" class="pt-1">
                  <label>Salary frequency</label>
                  <v-select
                    color="primary"
                    v-model.trim="item.app_PrevSalUnit"
                    :items="lookupSalaryFrequency"
                    clearable
                    outlined
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" class="pt-1">
                  <ValidationProvider
                    :immediate="true"
                    :rules="{
                      valid_date_format: true,
                      current_date_compare: { opt: 'lteq' },
                    }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      color="primary"
                      label="From (date)"
                      v-model.trim="item.app_PrevFromDate"
                      maxlength="20"
                      :error-messages="errors"
                      clearable
                      outlined
                    >
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" class="pt-1">
                  <ValidationProvider
                    :immediate="true"
                    :rules="{
                      valid_date_format: true,
                    }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      color="primary"
                      label="To (date)"
                      v-model.trim="item.app_PrevToDate"
                      maxlength="20"
                      :error-messages="errors"
                      clearable
                      outlined
                    >
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" class="pt-1">
                  <ValidationProvider
                    :immediate="true"
                    :rules="{ max: 1000 }"
                    v-slot="{ errors }"
                  >
                    <v-textarea
                      color="primary"
                      label="Reasons for leaving"
                      v-model.trim="item.app_PrevReasons"
                      maxlength="1000"
                      :error-messages="errors"
                      clearable
                      outlined
                    >
                    </v-textarea>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </div>
            <div class="d-flex justify-center pt-4">
              <v-btn
                @click="openPanel(9)"
                :disabled="invalid"
                color="blue accent-1"
                outlined
                rounded
                >Next section</v-btn
              >
            </div>
          </ValidationObserver>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel :disabled="!panelsEnabled[9]">
        <v-expansion-panel-header>Education</v-expansion-panel-header>
        <v-expansion-panel-content>
          <div>
            <v-btn
              @click="addNewSection_AppEducation"
              class="ma-1"
              color="primary"
              small
              >Add new section</v-btn
            >
            <v-btn
              @click="removeAllSections_AppEducation"
              class="ma-1"
              color="primary"
              small
            >
              Remove all sections
            </v-btn>
          </div>
          <ValidationObserver v-slot="{ invalid }">
            <div
              class="app-subform-section"
              v-for="(item, i) in fdata.subForm_AppEducation"
              :key="i"
            >
              <v-chip
                @click:close="removeSection_AppEducation(i)"
                class="mb-3"
                close
                color="secondary"
                text-color="white"
              >
                Section ({{ i + 1 }} of {{ fdata.subForm_AppEducation.length }})
              </v-chip>
              <v-row dense>
                <v-col cols="12">
                  <ValidationProvider
                    :immediate="true"
                    :rules="{ max: 100 }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      color="primary"
                      label="School / College / University"
                      v-model.trim="item.app_EduOrg"
                      maxlength="100"
                      :error-messages="errors"
                      clearable
                      outlined
                    >
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" class="pt-1">
                  <ValidationProvider
                    :immediate="true"
                    :rules="{
                      valid_date_format: true,
                      current_date_compare: { opt: 'lteq' },
                    }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      color="primary"
                      label="From (date)"
                      v-model.trim="item.app_EduFromDate"
                      maxlength="20"
                      :error-messages="errors"
                      clearable
                      outlined
                    >
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" class="pt-1">
                  <ValidationProvider
                    :immediate="true"
                    :rules="{
                      valid_date_format: true,
                    }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      color="primary"
                      label="To (date)"
                      v-model.trim="item.app_EduToDate"
                      maxlength="20"
                      :error-messages="errors"
                      clearable
                      outlined
                    >
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" class="pt-1">
                  <ValidationProvider
                    :immediate="true"
                    :rules="{ max: 1000 }"
                    v-slot="{ errors }"
                  >
                    <v-textarea
                      color="primary"
                      label="Results / qualification"
                      v-model.trim="item.app_EduResults"
                      maxlength="1000"
                      :error-messages="errors"
                      clearable
                      outlined
                    >
                    </v-textarea>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </div>
            <div class="d-flex justify-center pt-4">
              <v-btn
                @click="openPanel(10)"
                :disabled="invalid"
                color="blue accent-1"
                outlined
                rounded
                >Next section</v-btn
              >
            </div>
          </ValidationObserver>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel :disabled="!panelsEnabled[10]">
        <v-expansion-panel-header>Trainings</v-expansion-panel-header>
        <v-expansion-panel-content>
          <div>
            <v-btn
              @click="addNewSection_AppTraining"
              class="ma-1"
              color="primary"
              small
              >Add new section</v-btn
            >
            <v-btn
              @click="removeAllSections_AppTraining"
              class="ma-1"
              color="primary"
              small
            >
              Remove all sections
            </v-btn>
          </div>
          <ValidationObserver v-slot="{ invalid }">
            <div
              class="app-subform-section"
              v-for="(item, i) in fdata.subForm_AppTraining"
              :key="i"
            >
              <v-chip
                @click:close="removeSection_AppTraining(i)"
                class="mb-3"
                close
                color="secondary"
                text-color="white"
              >
                Section ({{ i + 1 }} of {{ fdata.subForm_AppTraining.length }})
              </v-chip>
              <v-row dense>
                <v-col cols="12">
                  <ValidationProvider
                    :immediate="true"
                    :rules="{ max: 1000 }"
                    v-slot="{ errors }"
                  >
                    <label
                      >Training details (Qualifications / Certificates
                      obtained)</label
                    >
                    <v-textarea
                      class="mt-2"
                      color="primary"
                      v-model.trim="item.app_TrnOrg"
                      maxlength="1000"
                      :error-messages="errors"
                      clearable
                      outlined
                    >
                    </v-textarea>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" class="pt-1">
                  <ValidationProvider
                    :immediate="true"
                    :rules="{
                      valid_date_format: true,
                      current_date_compare: { opt: 'lteq' },
                    }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      color="primary"
                      label="Date obtained"
                      v-model.trim="item.app_TrnDateObtained"
                      maxlength="20"
                      :error-messages="errors"
                      clearable
                      outlined
                    >
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </div>
            <div class="d-flex justify-center pt-4">
              <v-btn
                @click="openPanel(11)"
                :disabled="invalid"
                color="blue accent-1"
                outlined
                rounded
                >Next section</v-btn
              >
            </div>
          </ValidationObserver>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel :disabled="!panelsEnabled[11]">
        <v-expansion-panel-header
          >Additional information</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <ValidationObserver v-slot="{ invalid }">
            <v-row dense>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 1000 }"
                  v-slot="{ errors }"
                >
                  <label
                    >Is there anything else that you would like to say in
                    support of your application</label
                  >
                  <v-textarea
                    class="mt-2"
                    color="primary"
                    v-model.trim="fdata.app_AddInfo"
                    maxlength="1000"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-textarea>
                </ValidationProvider>
              </v-col>
            </v-row>
            <div class="d-flex justify-center pt-4">
              <v-btn
                @click="openPanel(12)"
                :disabled="invalid"
                color="blue accent-1"
                outlined
                rounded
                >Next section</v-btn
              >
            </div>
          </ValidationObserver>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel :disabled="!panelsEnabled[12]">
        <v-expansion-panel-header>First reference</v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>
            Please give details of two referees, the first of which should be
            your most recent employer. The second should be a personal reference
            from a person (not a relative or friend) who has known you for at
            least three years and is a professional (e.g. teacher, nurse,
            Minister of Religion).
          </p>
          <ValidationObserver v-slot="{ invalid }">
            <v-row dense>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100, required: true }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Name"
                    v-model.trim="fdata.app_RefName"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Occupation"
                    v-model.trim="fdata.app_RefOcu"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Company name"
                    v-model.trim="fdata.app_RefCompName"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true, max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Mobile / telephone number"
                    v-model.trim="fdata.app_RefTel"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true, max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Relationship to applicant"
                    v-model.trim="fdata.app_RefRel"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <v-select
                    color="primary"
                    label="May we take up this reference now"
                    v-model.trim="fdata.app_RefTakeNow"
                    :error-messages="errors"
                    :items="lookupYesNo"
                    clearable
                    outlined
                  >
                  </v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true, max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="House number / First line of address"
                    v-model.trim="fdata.app_RefAddress1"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Street / Road"
                    v-model.trim="fdata.app_RefAddress2"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Locality"
                    v-model.trim="fdata.app_RefAddress3"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Town / City"
                    v-model.trim="fdata.app_RefTown"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="County"
                    v-model.trim="fdata.app_RefCounty"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{
                    required: true,
                    max: 10,
                    valid_uk_postcode: true,
                  }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Postcode"
                    v-model.trim="fdata.app_RefPostcode"
                    maxlength="10"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <v-combobox
                    color="primary"
                    label="Country"
                    v-model="fdata.app_RefCountry"
                    :error-messages="errors"
                    :items="lookupCountry"
                    clearable
                    outlined
                  >
                  </v-combobox>
                </ValidationProvider>
              </v-col>
            </v-row>
            <div class="d-flex justify-center pt-4">
              <v-btn
                @click="openPanel(13)"
                :disabled="invalid"
                color="blue accent-1"
                outlined
                rounded
                >Next section</v-btn
              >
            </div>
          </ValidationObserver>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel :disabled="!panelsEnabled[13]">
        <v-expansion-panel-header>Second reference</v-expansion-panel-header>
        <v-expansion-panel-content>
          <ValidationObserver v-slot="{ invalid }">
            <v-row dense>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100, required: true }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Name"
                    v-model.trim="fdata.app_RefName2"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Occupation"
                    v-model.trim="fdata.app_RefOcu2"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Company name"
                    v-model.trim="fdata.app_RefCompName2"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true, max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Mobile / telephone number"
                    v-model.trim="fdata.app_RefTel2"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true, max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Relationship to applicant"
                    v-model.trim="fdata.app_RefRel2"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <v-select
                    color="primary"
                    label="May we take up this reference now"
                    v-model.trim="fdata.app_RefTakeNow2"
                    :error-messages="errors"
                    :items="lookupYesNo"
                    clearable
                    outlined
                  >
                  </v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true, max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="House number / First line of address"
                    v-model.trim="fdata.app_RefAddress11"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Street / Road"
                    v-model.trim="fdata.app_RefAddress22"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Locality"
                    v-model.trim="fdata.app_RefAddress23"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Town / City"
                    v-model.trim="fdata.app_RefTown2"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="County"
                    v-model.trim="fdata.app_RefCounty2"
                    maxlength="100"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{
                    required: true,
                    max: 10,
                    valid_uk_postcode: true,
                  }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="primary"
                    label="Postcode"
                    v-model.trim="fdata.app_RefPostcode2"
                    maxlength="10"
                    :error-messages="errors"
                    clearable
                    outlined
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <v-combobox
                    color="primary"
                    label="Country"
                    v-model="fdata.app_RefCountry2"
                    :error-messages="errors"
                    :items="lookupCountry"
                    clearable
                    outlined
                  >
                  </v-combobox>
                </ValidationProvider>
              </v-col>
            </v-row>
            <div class="d-flex justify-center pt-4">
              <v-btn
                @click="openPanel(14)"
                :disabled="invalid"
                color="blue accent-1"
                outlined
                rounded
                >Next section</v-btn
              >
            </div>
          </ValidationObserver>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel :disabled="!panelsEnabled[14]">
        <v-expansion-panel-header>Declaration</v-expansion-panel-header>
        <v-expansion-panel-content>
          <ValidationObserver ref="formRef" v-slot="{ invalid }">
            <v-row dense>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{
                    required: true,
                    data_item_compare: {
                      opt: 'eq',
                      targetValue: 2,
                      dataType: 'number',
                      msg: 'This question must be YES',
                    },
                  }"
                  v-slot="{ errors }"
                >
                  <label
                    >I confirm that the information contained in this
                    application is true and complete. I accept that providing
                    deliberately false information could result in my
                    dismissal.</label
                  >
                  <v-select
                    class="mt-2"
                    color="primary"
                    v-model.trim="fdata.app_DecInfoTrue"
                    :error-messages="errors"
                    :items="lookupYesNo"
                    clearable
                    outlined
                  >
                  </v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pt-1">
                <ValidationProvider
                  :immediate="true"
                  :rules="{
                    required: true,
                    data_item_compare: {
                      opt: 'eq',
                      targetValue: 2,
                      dataType: 'number',
                      msg: 'This question must be YES',
                    },
                  }"
                  v-slot="{ errors }"
                >
                  <label
                    >I confirm that I consider myself to be well enough in my
                    physical and mental health to cope with the demands of the
                    post I have applied for with Rehoboth24 Healthcare Solutions
                    Ltd.</label
                  >
                  <v-select
                    class="mt-2"
                    color="primary"
                    v-model.trim="fdata.app_DecWell"
                    :error-messages="errors"
                    :items="lookupYesNo"
                    clearable
                    outlined
                  >
                  </v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
            <div class="d-flex justify-center">
              <v-btn
                @click="saveFormData"
                :disabled="invalid"
                :loading="showBusyAni"
                color="primary"
                large
                >Submit Application</v-btn
              >
            </div>
            <div class="app-recaptcha-terms">
              This site is protected by reCAPTCHA and the Google
              <a href="https://policies.google.com/privacy">Privacy Policy</a>
              and
              <a href="https://policies.google.com/terms">Terms of Service</a>
              apply.
            </div>
          </ValidationObserver>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <AsyncDialog ref="confirmDialog" />
    <v-snackbar v-model="showSnackbar" :color="snackbarColor" top>
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" @click="showSnackbar = false" text> Close </v-btn>
      </template>
    </v-snackbar>
  </form>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import axios from "axios";
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    AsyncDialog: () => import("./AsyncDialog"),
  },
  data: () => ({
    fdata: {
      app_Position: "",
      app_PositionText: "",
      app_Title: "",
      app_TitleText: "",
      app_FirstName: "",
      app_MiddleName: "",
      app_Surname: "",
      app_DateOfBirth: "",
      app_MobileNumber: "",
      app_Email: "",
      app_Address1: "",
      app_Address2: "",
      app_Address3: "",
      app_Town: "",
      app_County: "",
      app_Postcode: "",
      app_Country: "",
      app_EmpNI: "",
      app_NextOfKin: "",
      app_NextOfKinRel: "",
      app_NextOfKinTel: "",
      app_EmpTemp: "",
      app_EmpRegPartTime: "",
      app_EmpRegFullTime: "",
      app_AvMonday: "",
      app_AvTuesday: "",
      app_AvWednesday: "",
      app_AvThursday: "",
      app_AvFriday: "",
      app_AvSaturday: "",
      app_AvSunday: "",
      app_AvStartDate: "",
      app_AvCanWorkEv: "",
      app_AvDLtype: "",
      app_AvOwnTpt: "",
      app_AvDBScheck: "",
      app_AvDBSref: "",
      app_PempName: "",
      app_PempTel: "",
      app_PempBType: "",
      app_PempPos: "",
      app_PempFromDate: "",
      app_PempToDate: "",
      app_PempResps: "",
      app_PempNotice: "",
      app_PempAddress1: "",
      app_PempAddress2: "",
      app_PempAddress3: "",
      app_PempTown: "",
      app_PempCounty: "",
      app_PempPostcode: "",
      app_PempCountry: "",
      app_AddInfo: "",
      app_RefName: "",
      app_RefOcu: "",
      app_RefCompName: "",
      app_RefTel: "",
      app_RefRel: "",
      app_RefTakeNow: "",
      app_RefAddress1: "",
      app_RefAddress2: "",
      app_RefAddress3: "",
      app_RefTown: "",
      app_RefCounty: "",
      app_RefPostcode: "",
      app_RefCountry: "",
      app_RefName2: "",
      app_RefOcu2: "",
      app_RefCompName2: "",
      app_RefTel2: "",
      app_RefRel2: "",
      app_RefTakeNow2: "",
      app_RefAddress12: "",
      app_RefAddress22: "",
      app_RefAddress32: "",
      app_RefTown2: "",
      app_RefCounty2: "",
      app_RefPostcode2: "",
      app_RefCountry2: "",
      app_DecInfoTrue: "",
      app_DecWell: "",
      cap_RecaptchaResponse: "",
      subForm_AppPrevEmp: [],
      subForm_AppEducation: [],
      subForm_AppTraining: [],
    },
    showSnackbar: false,
    snackbarMessage: "",
    snackbarColor: "error",
    showBusyAni: false,

    panelsEnabled: [
      true,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],

    app_PositionText_enabled: false,
    app_TitleText_enabled: false,

    activePanel: 0,

    lookupPosition: [
      {
        value: 1,
        text: "Nurse",
      },
      {
        value: 2,
        text: "Care Assistant / Support Worker",
      },
      {
        value: 97,
        text: "Other",
      },
    ],
    lookupTitle: [
      {
        value: 1,
        text: "Ms",
      },
      {
        value: 2,
        text: "Miss",
      },
      {
        value: 3,
        text: "Mrs",
      },
      {
        value: 4,
        text: "Mr",
      },
      {
        value: 5,
        text: "Dr",
      },
      {
        value: 97,
        text: "Other",
      },
    ],
    lookupCountry: ["United Kingdom (UK)"],
    lookupYesNo: [
      {
        value: 1,
        text: "No",
      },
      {
        value: 2,
        text: "Yes",
      },
    ],
    lookupDrivingLicence: [
      {
        value: 1,
        text: "Full",
      },
      {
        value: 2,
        text: "Provisional",
      },
      {
        value: 3,
        text: "None",
      },
    ],
    lookupSalaryFrequency: [
      {
        value: 1,
        text: "Weekly",
      },
      {
        value: 2,
        text: "Monthly",
      },
      {
        value: 3,
        text: "Yearly",
      },
    ],
  }),
  watch: {
    "fdata.app_Position"(newVal) {
      if ([97].includes(newVal)) {
        this.app_PositionText_enabled = true;
      } else {
        this.fdata.app_PositionText = "";
        this.app_PositionText_enabled = false;
      }
    },
    "fdata.app_Title"(newVal) {
      if ([97].includes(newVal)) {
        this.app_TitleText_enabled = true;
      } else {
        this.fdata.app_TitleText = "";
        this.app_TitleText_enabled = false;
      }
    },
  },
  methods: {
    async recaptcha() {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("submit_job_application");
      return token;
    },
    async saveFormData() {
      //debugger;
      this.snackbarColor = "error";
      let isValid = await this.$refs.formRef.validate();
      if (!isValid) {
        this.snackbarMessage = "Form contains validation warning(s).";
        this.showSnackbar = true;
        return;
      }
      //Fetch recaptcha token
      const token = await this.recaptcha();
      if (!token) {
        return;
      } else {
        this.fdata.cap_RecaptchaResponse = token;
      }
      this.showBusyAni = true;
      const actionUrl = "/Application/SubmitForm";
      try {
        const status = await axios.post(actionUrl, this.fdata);
        if (status) {
          this.showBusyAni = false;
          this.resetFormData();
          this.snackbarColor = "success";
          this.snackbarMessage = "Form successfully submitted.";
          this.showSnackbar = true;
        }
      } catch (error) {
        this.showBusyAni = false;
        throw error;
      }
    },
    openPanel(index) {
      this.panelsEnabled[index] = true;
      this.activePanel = index;
    },
    addNewSection_AppPrevEmp() {
      this.fdata.subForm_AppPrevEmp.push({
        app_PrevName: "",
        app_PrevPos: "",
        app_PrevSal: "",
        app_PrevSalUnit: "",
        app_PrevFromDate: "",
        app_PrevToDate: "",
        app_PrevReasons: "",
      });
    },
    addNewSection_AppEducation() {
      this.fdata.subForm_AppEducation.push({
        app_EduOrg: "",
        app_EduFromDate: "",
        app_EduToDate: "",
        app_EduResults: "",
      });
    },
    addNewSection_AppTraining: function () {
      this.fdata.subForm_AppTraining.push({
        app_TrnOrg: "",
        app_TrnDateObtained: "",
      });
    },
    resetSubFormData() {
      this.addNewSection_AppPrevEmp();
      this.addNewSection_AppEducation();
      this.addNewSection_AppTraining();
    },
    //Employment
    async removeAllSections_AppPrevEmp() {
      const dialogResult = await this.$refs.confirmDialog.show({
        message: "<b>Previous employment:</b> You are removing all sections",
      });
      if (dialogResult) {
        this.fdata.subForm_AppPrevEmp = [];
      }
    },
    async removeSection_AppPrevEmp(index) {
      const dialogResult = await this.$refs.confirmDialog.show({
        message: `<b>Previous employment:</b> You are removing section ${
          index + 1
        } of ${this.fdata.subForm_AppPrevEmp.length}`,
      });
      if (dialogResult) {
        this.fdata.subForm_AppPrevEmp.splice(index, 1);
      }
    },
    //Education
    async removeAllSections_AppEducation() {
      const dialogResult = await this.$refs.confirmDialog.show({
        message: "<b>Education:</b> You are removing all sections",
      });
      if (dialogResult) {
        this.fdata.subForm_AppEducation = [];
      }
    },
    async removeSection_AppEducation(index) {
      const dialogResult = await this.$refs.confirmDialog.show({
        message: `<b>Education:</b> You are removing section ${index + 1} of ${
          this.fdata.subForm_AppEducation.length
        }`,
      });
      if (dialogResult) {
        this.fdata.subForm_AppEducation.splice(index, 1);
      }
    },
    //Training
    async removeAllSections_AppTraining() {
      const dialogResult = await this.$refs.confirmDialog.show({
        message: "<b>Trainings:</b> You are removing all sections",
      });
      if (dialogResult) {
        this.fdata.subForm_AppTraining = [];
      }
    },
    async removeSection_AppTraining(index) {
      const dialogResult = await this.$refs.confirmDialog.show({
        message: `<b>Trainings:</b> You are removing section ${index + 1} of ${
          this.fdata.subForm_AppTraining.length
        }`,
      });
      if (dialogResult) {
        this.fdata.subForm_AppTraining.splice(index, 1);
      }
    },
    resetFormData() {
      Object.assign(this.fdata, {
        app_Position: "",
        app_PositionText: "",
        app_Title: "",
        app_TitleText: "",
        app_FirstName: "",
        app_MiddleName: "",
        app_Surname: "",
        app_DateOfBirth: "",
        app_MobileNumber: "",
        app_Email: "",
        app_Address1: "",
        app_Address2: "",
        app_Address3: "",
        app_Town: "",
        app_County: "",
        app_Postcode: "",
        app_Country: "",
        app_EmpNI: "",
        app_NextOfKin: "",
        app_NextOfKinRel: "",
        app_NextOfKinTel: "",
        app_EmpTemp: "",
        app_EmpRegPartTime: "",
        app_EmpRegFullTime: "",
        app_AvMonday: "",
        app_AvTuesday: "",
        app_AvWednesday: "",
        app_AvThursday: "",
        app_AvFriday: "",
        app_AvSaturday: "",
        app_AvSunday: "",
        app_AvStartDate: "",
        app_AvCanWorkEv: "",
        app_AvDLtype: "",
        app_AvOwnTpt: "",
        app_AvDBScheck: "",
        app_AvDBSref: "",
        app_PempName: "",
        app_PempTel: "",
        app_PempBType: "",
        app_PempPos: "",
        app_PempFromDate: "",
        app_PempToDate: "",
        app_PempResps: "",
        app_PempNotice: "",
        app_PempAddress1: "",
        app_PempAddress2: "",
        app_PempAddress3: "",
        app_PempTown: "",
        app_PempCounty: "",
        app_PempPostcode: "",
        app_PempCountry: "",
        app_AddInfo: "",
        app_RefName: "",
        app_RefOcu: "",
        app_RefCompName: "",
        app_RefTel: "",
        app_RefRel: "",
        app_RefTakeNow: "",
        app_RefAddress1: "",
        app_RefAddress2: "",
        app_RefAddress3: "",
        app_RefTown: "",
        app_RefCounty: "",
        app_RefPostcode: "",
        app_RefCountry: "",
        app_RefName2: "",
        app_RefOcu2: "",
        app_RefCompName2: "",
        app_RefTel2: "",
        app_RefRel2: "",
        app_RefTakeNow2: "",
        app_RefAddress12: "",
        app_RefAddress22: "",
        app_RefAddress32: "",
        app_RefTown2: "",
        app_RefCounty2: "",
        app_RefPostcode2: "",
        app_RefCountry2: "",
        app_DecInfoTrue: "",
        app_DecWell: "",
        cap_RecaptchaResponse: "",
        subForm_AppPrevEmp: [],
        subForm_AppEducation: [],
        subForm_AppTraining: [],
      });
      this.resetSubFormData();
    },
  },
  mounted() {
    this.resetSubFormData();
    /*
    Object.assign(this.fdata, {
        app_Position: 1,
        app_Title: 2,
        app_FirstName: "Yaa",
        app_MiddleName: "Mansah",
        app_Surname: "Attoh",
        app_DateOfBirth: "30-09-1989",
        app_MobileNumber: "07507518885",
        app_Email: "henryadjei2k2@yahoo.com",
        app_Address1: "gagagagag",
        app_Address2: "ahahahha",
        app_Address3: "hhagahaha",
        app_Town: "hhagahhha",
        app_County: "hhahhahah",
        app_Postcode: "CV6 7GH",
        app_Country: "United Kingdom",
        app_EmpNI: "55262526",
        app_NextOfKin: "hhhwhwbwh",
        app_NextOfKinRel: "wjwjwhjwkkw",
        app_NextOfKinTel: "027262727",
        app_EmpTemp: 1,
        app_EmpRegPartTime: 2,
        app_EmpRegFullTime: 2,
        app_AvMonday: 2,
        app_AvTuesday: 2,
        app_AvWednesday: 6,
        app_AvThursday: 7,
        app_AvFriday: 6,
        app_AvSaturday: 7,
        app_AvSunday: 8,
        app_AvStartDate: "30-09-2023",
        app_AvCanWorkEv: 1,
        app_AvDLtype: 1,
        app_AvOwnTpt: 2,
        app_AvDBScheck: 2,
        app_AvDBSref: "hggsgs222",
        app_PempName: "hahagahaha",
        app_PempTel: "08886888888",
        app_PempBType: "agagagag",
        app_PempPos: "hahahahahah",
        app_PempFromDate: "30-09-2020",
        app_PempToDate: "17-09-2022",
        app_PempResps: "ahahahahah",
        app_PempNotice: "2 days",
        app_PempAddress1: "bbsbsbsbs",
        app_PempAddress2: "nnsnnsns jsjjsj",
        app_PempAddress3: "nnsnnsnns snnsn",
        app_PempTown: "ggfshhsgh",
        app_PempCounty: "nnsnsnns nsnns",
        app_PempPostcode: "CV7 8GH",
        app_PempCountry: "Gfagga ahah",
        app_AddInfo: "hahaha ahahah",
        app_RefName: "hhaaha hahah",
        app_RefOcu: "hahah hahaha",
        app_RefCompName: "aabab aahah",
        app_RefTel: "088887888887",
        app_RefRel: "hgahahahahha",
        app_RefTakeNow: 2,
        app_RefAddress1: "ananananan",
        app_RefAddress2: "nnananan",
        app_RefAddress3: "nnanana",
        app_RefTown: "jjajajaj",
        app_RefCounty: "nanannamma",
        app_RefPostcode: "CV5 3FG",
        app_RefCountry: "nnnannan an",
        app_RefName2: "aajjaja jaja",
        app_RefOcu2: "kka hhahajjja",
        app_RefCompName2: "nnananannn",
        app_RefTel2: "jjajajajja",
        app_RefRel2: "jjajja  jajaj",
        app_RefTakeNow2: 2,
        app_RefAddress12: "yayyaaybyayb ay",
        app_RefAddress22: "aauauaua aua",
        app_RefAddress32: "uauauau uau",
        app_RefTown2: "ggagagbshhs hs",
        app_RefCounty2: "aiiaia aiaiai",
        app_RefPostcode2: "iaiauauaauua",
        app_RefCountry2: "iiaiaiaia",
        app_DecInfoTrue: 2,
        app_DecWell: 2,
        cap_RecaptchaResponse: "",
      });*/
  },
};
</script>
<style scoped>
.v-input {
  font-size: 18px;
}
.v-expansion-panel-header {
  font-size: 18px;
  font-weight: 600;
  color: var(--color-primary);
}
.v-input--is-disabled:not(.v-input--is-readonly) {
  background-color: #f2f2f2;
}
.app-subform-section {
  border: 1px dashed #000000;
  border-radius: 7px;
  padding: 10px 20px;
  margin-top: 5px;
}
.app-recaptcha-terms {
  margin-top: 20px;
  color: #6c6b6b;
  text-align: center;
  font-size: 11px;
}
.app-form label {
  font-size: 16px;
}
.row + .row.no-gutters {
  margin: 0;
}
</style>
